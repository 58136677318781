import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Checkbox, Drawer, Form, Select, Input } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { actions } from "../../../store/index";
import { REQUESTS } from "../../../api/requests";

import Message from "../../../components/message";

import TEXTS from "../../../config/texts";

import ValidateMacAddress from "../../../components/ValidateMacAddress";

import classes from "../style/activateDeviceDrawer.module.scss";

import CONSTANTS from "../../../config/index";

// Activate device drawer component. The component is used in the ActivatedDevice page for to activate the device. The component uses the
// REQUESTS.ACTIVATED_DEVICE.ACTIVATE function to activate the device. The component uses the REQUESTS.PACKAGES function to get the packages data.

const ActivateDeviceDrawer = ({
  onClose,
  visible,
  getDevices,
  removeSearchSorter,
  currentDevice
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();

  const { setProfile } = bindActionCreators(actions, dispatch); // is used to set the profile data in the global state.

  const translation = useSelector((state) => state.globalState.translation); //  is used to get the translation data from the global state.

  const appInfo = useSelector((state) => state.globalState.appInfo); // is used to get the app info data from the global state.

  const [loading, setLoading] = useState(false); //is used to show the loading animation when the request is sent to the server.

  const [isDisable, setIsDisable] = useState(true); // is used to disable the submit button if the user does not accept the privacy policy.

  const [macValue, setMacValue] = useState("");

  const [message, setMessage] = useState({
    // is used to show the message to the user. The message can be an error or a success message.
    text: "",
    type: true,
  });

  const [packages, setPackages] = useState(null); // State is used to store the packages data.

  const [packageName, setPackageName] = useState('');

  const paymentSettings = useSelector(
    (state) => state.globalState.pymentSettings
  );

  const [form] = Form.useForm();

  const onFinish = (values) => {
    //is used to send the request to the server to activate the device.
    setLoading(true);

    const body = {
      mac: macValue,
      package_id: values.package_id,
    };

    if (values.comment) {
      body.comment = values.comment;
    }

    function callback () {
      setLoading(false);
      onClose();
      removeSearchSorter();
      getDevices();
      getProfile();
    }

    function errorCallback (error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.ACTIVATED_DEVICE.ACTIVATE(body, callback, errorCallback);
  };

  const onValuesChange = (changed, all) => {
    //is used to remove the message and to enable the submit button if the user accepts the privacy policy.
    removeMessage();

    if (all.privacy_policy) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    //is used to submit the form.
    removeMessage();
    form.submit();
  };

  const getPackages = () => {
    // is used to get the packages data from the server.
    REQUESTS.PACKAGES((data) => setPackages(data.rows));
  };

  const getProfile = () => {
    // is used to get the profile data from the server.
    function callback (data) {
      setProfile(data);
    }
    REQUESTS.PROFILE.GET(callback);
  };

  useEffect(() => {
    // is used to reset the form and to get the packages data when the component is visible.
    if (visible) {
      // form.resetFields();
      // setMacValue('')
      getPackages();
    } else {
      form.resetFields();
      setMacValue('')
      removeMessage();
      setLoading(false);
    }
  }, [visible]);

  useEffect(() => {
    if (currentDevice) {
      form.setFields([
        { name: "comment", value: currentDevice.reseller_activation?.comment || "" },
      ]);

      setMacValue(currentDevice.mac);

      if (packages) {
        let name = packages.find(item => item.id == currentDevice?.package_id)?.['id'];
        if (name) {
          form.setFields([
            { name: "package_id", value: name || "" },
          ]);
        }
      }
    }

  }, [currentDevice, packages]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <Drawer
      width={windowWidth > 600 ? 600 : "75vw"}
      title={translation["Activate device"] || TEXTS["Activate device"]}
      placement="right"
      onClose={() => {
        onClose();
        setMacValue("");
      }}
      open={visible}
    >
      <Form
        form={form}
        layout={"vertical"}
        name="activate device"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <ValidateMacAddress value={macValue} setValue={setMacValue} />

        <Form.Item
          label={translation["Package"] || TEXTS["Package"]}
          rules={[
            {
              required: true,
              message: translation["This field is required!"] || TEXTS["This field is required!"]
            },
          ]}
          name="package_id"
        >
          <Select
            className={classes["packages-select"]}
            placeholder={translation["Select package"] || TEXTS["Select package"]}
          >
            {packages &&
              packages.map((item) => {
                const { name, activation_needed, id, schedule } = item;
                return (
                  <Select.Option
                    key={id}
                    value={id}
                  >
                    {`${ name } (${ activation_needed } ${ translation["Credits"] || TEXTS["Credits"] }, ${ schedule })`}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="comment"
          label={translation["Comment"] || TEXTS["Comment"]}
        >
          <Input
            placeholder={translation["Enter text"] || TEXTS["Enter text"]}
          />
        </Form.Item>
        <div className={classes["activate-checkbox-block"]}>
          <Form.Item
            className={classes[""]}
            name="privacy_policy"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item>
            <a
              href={CONSTANTS.REACT_APP_WEB_DOMAIN + "#/privacy-policy"}
              target={"_blank"}
            >
              {translation["Privacy policy"] || TEXTS["Privacy policy"]}
            </a>
          </Form.Item>
        </div>

        <Message message={message} />

        <Form.Item className={classes["activate-button"]}>
          <Button
            type="primary"
            loading={loading}
            disabled={isDisable}
            onClick={onSubmit}
            icon={<CheckOutlined />}
          >
            {translation["Save"] || TEXTS["Save"]}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ActivateDeviceDrawer;
